import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "How to drive React state with D3 transitions for complex animation",
  "description": "Here's part 2 of Kiran's challenge from last week 👉 animating our drilldown piechart.",
  "date": "2019-07-01T08:00:00.000Z",
  "published": "2019-07-01T08:00:00.000Z",
  "image": "./img/techletter.app-screenshot-1561996966775.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Here`}{`'`}{`s part 2 of `}<a parentName="p" {...{
        "href": "https://reactfordataviz.com/articles/a-drilldown-piechart-with-react-and-d3/"
      }}>{`Kiran B`}{`'`}{`s challenge`}</a>{` from last week 👉 animating our drilldown piechart.`}</p>
    <p><img parentName="p" {...{
        "src": "/add688a4b40298522cbb93b9c8d5ffa1/ygw2Esu.gif",
        "alt": null
      }}></img></p>
    <p>{`You can watch the whole stream or keep reading. This was fun to figure out. I`}{`'`}{`m loving the flexibility of this hybrid approach to animation.`}</p>
    <p>{`Yelling at my computer when it got too slow to run CodeSandbox was A+ 👌`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=LmAFUmmsRyE"
      }}><figure parentName="a" {...{
          "className": "gatsby-resp-image-figure",
          "style": {
            "textAlign": "center",
            "fontStyle": "italic"
          }
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "640px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.05381165919282%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'225\\'%20viewBox=\\'0%200%20400%20225\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M2%202L1%203%200%2084v81h16l-1%2011v14l1-5c0-7%201-9%203-9l6-2c3-1%204-2%209-7%203-2%207-3%2048-2h24v3l1%204h105c140%200%20126%200%20126%202l-25%201h-25l1%2014c0%2014-1%2026-2%2025l-1-20v-19h-90a1475%201475%200%2000-89%203l1%201%207%202%206%203v18c0%206-2%208-5%206l-2-1-4-1c-4-1-4-1-4%206v5H90c-14%200-17-1-18-2h-1l-1%201h-2l-2-1c-2-1-2-2-1-4%201-4%200-9-1-10-3-1-3-6%200-6%202%201%205-5%203-6h-3c-3%201-15%201-14-1%203-4%2012-5%2015-3%201%202%202%201%201-2-5-14-25-15-33-2-2%203-2%204-1%208l1%208%202%206c3%205%204%208%202%207l-1%201c1%203%200%202-2-1s-6-4-6-2h-2l-3-1c-1%201-2%200-1-3l-1-4-1%203-2%202-3%201c-1%201-1%200-1-1l1-3c2%200%202-10%200-11l-1%204-1%201c-1-1-1%200-1%202l-1%205-1%204v2l-2-2-2-2c0%201-2%200-2-2-3-4-4-2-4%2010v12h401V3h-7l-7-1c0-2-2-3-2-1-1%202-24%202-24%200s-3-1-3%201h-1c-2-2-3-2-3%200l-1%201-1-1-1-2-1%202-1%201-1-2h-1c-1%202-10%202-9%200l-1-1-1%202-100%201A2690%202690%200%200138%203L16%202c0-1-1-2-3-2-3%200-4%201-2%202%201%201%200%201-2%201L5%202%204%200%202%202m228%203c-3%200-4%200-4%202s1%202%2053%202a658%20658%200%200054-3h-11c-4-1-22-2-26%200h-7l-6-1h-3a226%20226%200%2000-40%200h-10m-38%202c0%202%200%202%2016%202s17%200%2016-2c0-1-1-2-2-1l-22-1c-8%200-8%200-8%202M84%2011c-4%204-2%204%2097%204a3003%203003%200%200098-3c0-2-193-3-195-1m122%2061l1%2033c4%200%204%2013%201%2014-2%200-2%201-2%2023l1%2023h132v-61c0-60%200-61%202-61%203%200%202-1-1-3l-68-1h-66v33m63-8l-2%202c3%209%204%2014%203%2016s-1%204%203%205l5%207c4%208%205%208%209%204%2014-14%200-39-18-34\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/0ad462c54e2c34609db4bcbe05dc2424/ca0a1/techletter.app-screenshot-1561996966775.webp 223w", "/static/0ad462c54e2c34609db4bcbe05dc2424/75680/techletter.app-screenshot-1561996966775.webp 445w", "/static/0ad462c54e2c34609db4bcbe05dc2424/0ba47/techletter.app-screenshot-1561996966775.webp 640w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/0ad462c54e2c34609db4bcbe05dc2424/e92b6/techletter.app-screenshot-1561996966775.png 223w", "/static/0ad462c54e2c34609db4bcbe05dc2424/e66bf/techletter.app-screenshot-1561996966775.png 445w", "/static/0ad462c54e2c34609db4bcbe05dc2424/6af66/techletter.app-screenshot-1561996966775.png 640w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/0ad462c54e2c34609db4bcbe05dc2424/6af66/techletter.app-screenshot-1561996966775.png",
                "alt": "Click through for source",
                "title": "Click through for source",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`Click through for source`}</figcaption>{`
  `}</figure></a></p>
    <p>{`And if you prefer to jump straight into messing around with code, here`}{`'`}{`s the CodeSandbox`}</p>
    <p><a parentName="p" {...{
        "href": "https://codesandbox.io/s/drilldown-piechart-in-react-and-d3-d62y5"
      }}><figure parentName="a" {...{
          "className": "gatsby-resp-image-figure",
          "style": {
            "textAlign": "center",
            "fontStyle": "italic"
          }
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "640px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "54.26008968609865%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'217\\'%20viewBox=\\'0%200%20400%20217\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M0%20109v108h401V0H0v109m229%204v98h171V15H229v98m83-62v10l-7-6-6-7-2%203c-7%209-6%2020%201%2028l4%204%206-7c3-5%206-8%207-8%203-1%204-6%202-7-2%200-2-2-2-10s0-9-2-9c-1%200-2%201-1%209M3%20150c-1%204%200%204%2026%204h25v-5H29l-26%201m-1%2035v10h7c4%200%207%200%206-1a3830%203830%200%2001-12-17c-1-1-1%201-1%208\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/5d88d3ff8a2071b6727dd308af286337/ca0a1/techletter.app-screenshot-1561996982609.webp 223w", "/static/5d88d3ff8a2071b6727dd308af286337/75680/techletter.app-screenshot-1561996982609.webp 445w", "/static/5d88d3ff8a2071b6727dd308af286337/0ba47/techletter.app-screenshot-1561996982609.webp 640w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/5d88d3ff8a2071b6727dd308af286337/e92b6/techletter.app-screenshot-1561996982609.png 223w", "/static/5d88d3ff8a2071b6727dd308af286337/e66bf/techletter.app-screenshot-1561996982609.png 445w", "/static/5d88d3ff8a2071b6727dd308af286337/6af66/techletter.app-screenshot-1561996982609.png 640w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/5d88d3ff8a2071b6727dd308af286337/6af66/techletter.app-screenshot-1561996982609.png",
                "alt": "Click through for source",
                "title": "Click through for source",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`Click through for source`}</figcaption>{`
  `}</figure></a></p>
    <h2 {...{
      "id": "wtf-is-hybrid-animation"
    }}>{`Wtf is hybrid animation?`}</h2>
    <p>{`Hybrid animation is a merger of two approaches to animation I teach in `}<a parentName="p" {...{
        "href": "https://reactfordataviz.com"
      }}>{`React for Data Visualization`}</a>{`. Also a brand new chapter I just added ✌️`}</p>
    <ol>
      <li parentName="ol">{`Change state 60 times per second, trigger re-renders, components look animated`}</li>
      <li parentName="ol">{`Give control to D3, use a transition, move back to React`}</li>
    </ol>
    <p>{`Both those approaches work great.`}</p>
    <p>{`You get heaps of control with the state-based approach, it`}{`'`}{`s fast, the animations look sick, and you spend a lot of brain cycles thinking about details.`}</p>
    <p>{`Transitions are easier, look great, and you mess with the DOM just a little. React doesn`}{`'`}{`t like that but will tolerate your shenanigans if you`}{`'`}{`re nice about it.`}</p>
    <p>{`But transitions fail when you have complex scenarios with animations spanning multiple components.`}</p>
    <p><img parentName="p" {...{
        "src": "/097400cbeea7dcf170e1ad1443dea5f0/media-3oriOaivTEk4PotVEQ-giphy.gif",
        "alt": "sad_trombone giphy"
      }}></img></p>
    <p>{`And that`}{`'`}{`s where hybrid animation comes in 👉 ease of transitions, full render control.`}</p>
    <h2 {...{
      "id": "cleaning-up-our-piechart"
    }}>{`Cleaning up our piechart`}</h2>
    <p>{`We`}{`'`}{`re working off of the `}<a parentName="p" {...{
        "href": "https://reactfordataviz.com/articles/a-drilldown-piechart-with-react-and-d3/"
      }}>{`drilldown piechart from last week`}</a>{`. So we`}{`'`}{`ve got the basics`}</p>
    <ol>
      <li parentName="ol">{`Hierarchical data where each slice has multiple children`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`<Arc>`}</inlineCode>{` component that renders individual slices`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`<DrilldownPie>`}</inlineCode>{` that uses a `}<inlineCode parentName="li">{`d3.pie()`}</inlineCode>{` generator and renders slices in a loop`}</li>
    </ol>
    <p>{`We cleaned up state management and added the drill-up feature.`}</p>
    <p><a parentName="p" {...{
        "href": "https://carbon.now.sh/?bg=rgba(255,255,255,1)&t=seti&l=javascript&ds=true&wc=true&wa=true&pv=48px&ph=32px&ln=false&code=function%20useDrillableData(data)%20%7B%0A%20%20const%20initialState%20%3D%20%7B%0A%20%20%20%20renderData%3A%20data%2C%0A%20%20%20%20stack%3A%20%5B%5D%2C%0A%20%20%20%20startAngle%3A%200%0A%20%20%7D%3B%0A%0A%20%20return%20useReducer((state%2C%20action)%20%3D%3E%20%7B%0A%20%20%20%20switch%20(action.type)%20%7B%0A%20%20%20%20%20%20case%20%22drilldown%22%3A%0A%20%20%20%20%20%20%20%20return%20%7B%0A%20%20%20%20%20%20%20%20%20%20renderData%3A%20state.renderData%5Baction.index%5D.children%2C%0A%20%20%20%20%20%20%20%20%20%20stack%3A%20%5B...state.stack%2C%20state.renderData%5D%2C%0A%20%20%20%20%20%20%20%20%20%20startAngle%3A%20action.startAngle%0A%20%20%20%20%20%20%20%20%7D%3B%0A%20%20%20%20%20%20case%20%22drillup%22%3A%0A%20%20%20%20%20%20%20%20if%20(state.stack.length%20%3E%200)%20%7B%0A%20%20%20%20%20%20%20%20%20%20return%20%7B%0A%20%20%20%20%20%20%20%20%20%20%20%20renderData%3A%20state.stack.slice(-1)%5B0%5D%2C%0A%20%20%20%20%20%20%20%20%20%20%20%20stack%3A%20state.stack.slice(0%2C%20-1)%2C%0A%20%20%20%20%20%20%20%20%20%20%20%20startAngle%3A%20state.startAngle%0A%20%20%20%20%20%20%20%20%20%20%7D%3B%0A%20%20%20%20%20%20%20%20%7D%20else%20%7B%0A%20%20%20%20%20%20%20%20%20%20return%20state%3B%0A%20%20%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20default%3A%0A%20%20%20%20%20%20%20%20return%20state%3B%0A%20%20%20%20%7D%0A%20%20%7D%2C%20initialState)%3B%0A%7D"
      }}><figure parentName="a" {...{
          "className": "gatsby-resp-image-figure",
          "style": {
            "textAlign": "center",
            "fontStyle": "italic"
          }
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "598px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "118.38565022421523%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'474\\'%20viewBox=\\'0%200%20400%20474\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M22%2034c-2%204-1%20405%201%20407%202%203%20352%203%20354%200%202-2%203-403%201-407-2-3-354-3-356%200\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/be828a22df2433e12d93fd43cec78240/ca0a1/techletter.app-screenshot-1561996984642.webp 223w", "/static/be828a22df2433e12d93fd43cec78240/75680/techletter.app-screenshot-1561996984642.webp 445w", "/static/be828a22df2433e12d93fd43cec78240/83245/techletter.app-screenshot-1561996984642.webp 598w"],
                "sizes": "(max-width: 598px) 100vw, 598px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/be828a22df2433e12d93fd43cec78240/e92b6/techletter.app-screenshot-1561996984642.png 223w", "/static/be828a22df2433e12d93fd43cec78240/e66bf/techletter.app-screenshot-1561996984642.png 445w", "/static/be828a22df2433e12d93fd43cec78240/0c69d/techletter.app-screenshot-1561996984642.png 598w"],
                "sizes": "(max-width: 598px) 100vw, 598px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/be828a22df2433e12d93fd43cec78240/0c69d/techletter.app-screenshot-1561996984642.png",
                "alt": "Click through for source",
                "title": "Click through for source",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`Click through for source`}</figcaption>{`
  `}</figure></a></p>
    <p><inlineCode parentName="p">{`useDrillableData`}</inlineCode>{` is a custom hook that takes our data and sets up a reducer so we can tie different state changes together.`}</p>
    <p>{`When you `}<inlineCode parentName="p">{`drilldown`}</inlineCode>{` we`}</p>
    <ul>
      <li parentName="ul">{`change `}<inlineCode parentName="li">{`renderData`}</inlineCode>{` to the children array; this creates the drilldown effect`}</li>
      <li parentName="ul">{`add current data to the `}<inlineCode parentName="li">{`stack`}</inlineCode>{`; this will help us know how to drill back up`}</li>
      <li parentName="ul">{`update `}<inlineCode parentName="li">{`startAngle`}</inlineCode>{` so pie animations look connected to what you clicked`}</li>
    </ul>
    <p>{`When you `}<inlineCode parentName="p">{`drilldown`}</inlineCode>{` we`}</p>
    <ul>
      <li parentName="ul">{`pop the previous data from the `}<inlineCode parentName="li">{`stack`}</inlineCode>{` and update `}<inlineCode parentName="li">{`renderData`}</inlineCode></li>
      <li parentName="ul">{`drop the last element in our `}<inlineCode parentName="li">{`stack`}</inlineCode></li>
      <li parentName="ul">{`keep the same `}<inlineCode parentName="li">{`startAngle`}</inlineCode></li>
    </ul>
    <p>{`If you`}{`'`}{`re at the highest level already, we do nothing.`}</p>
    <p>{`To use this new state management mechanism we replaced the previous `}<inlineCode parentName="p">{`useState`}</inlineCode>{` code with `}<inlineCode parentName="p">{`useDrillableData`}</inlineCode>{` and called `}<inlineCode parentName="p">{`dispatch`}</inlineCode>{` on click events instead of `}<inlineCode parentName="p">{`setState`}</inlineCode>{`.`}</p>
    <p><a parentName="p" {...{
        "href": "https://carbon.now.sh/?bg=rgba(255,255,255,1)&t=seti&l=javascript&ds=true&wc=true&wa=true&pv=48px&ph=32px&ln=false&code=const%20DrilldownPie%20%3D%20(%7B%20data%2C%20x%2C%20y%20%7D)%20%3D%3E%20%7B%0A%20%20const%20%5B%7B%20renderData%2C%20startAngle%20%7D%2C%20dispatch%5D%20%3D%20useDrillableData(data)%3B%0A%20%20%0A%20%20%2F%2F%20...%0A%20%20%0A%20%20function%20drilldown(%7B%20startAngle%2C%20index%20%7D)%20%7B%0A%20%20%20%20dispatch(%7B%20type%3A%20%22drilldown%22%2C%20index%2C%20startAngle%20%7D)%3B%0A%20%20%7D%0A%0A%20%20function%20drillup()%20%7B%0A%20%20%20%20dispatch(%7B%20type%3A%20%22drillup%22%20%7D)%3B%0A%20%20%7D"
      }}><figure parentName="a" {...{
          "className": "gatsby-resp-image-figure",
          "style": {
            "textAlign": "center",
            "fontStyle": "italic"
          }
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "678px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.95067264573991%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'227\\'%20viewBox=\\'0%200%20400%20227\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M20%2029l-1%2085v84l3%201h356l3-1v-84l-1-85c-2-2-358-2-360%200\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/c5b9a72abee787e01d7eab13155f0d74/ca0a1/techletter.app-screenshot-1561996964164.webp 223w", "/static/c5b9a72abee787e01d7eab13155f0d74/75680/techletter.app-screenshot-1561996964164.webp 445w", "/static/c5b9a72abee787e01d7eab13155f0d74/7e03f/techletter.app-screenshot-1561996964164.webp 678w"],
                "sizes": "(max-width: 678px) 100vw, 678px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/c5b9a72abee787e01d7eab13155f0d74/e92b6/techletter.app-screenshot-1561996964164.png 223w", "/static/c5b9a72abee787e01d7eab13155f0d74/e66bf/techletter.app-screenshot-1561996964164.png 445w", "/static/c5b9a72abee787e01d7eab13155f0d74/38cea/techletter.app-screenshot-1561996964164.png 678w"],
                "sizes": "(max-width: 678px) 100vw, 678px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/c5b9a72abee787e01d7eab13155f0d74/38cea/techletter.app-screenshot-1561996964164.png",
                "alt": "Click through for source",
                "title": "Click through for source",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`Click through for source`}</figcaption>{`
  `}</figure></a></p>
    <p>{`Now `}<inlineCode parentName="p">{`<DrilldownPie>`}</inlineCode>{` can go in both directions. That was a problem before 😅`}</p>
    <h2 {...{
      "id": "adding-hybrid-animation"
    }}>{`Adding hybrid animation`}</h2>
    <p>{`Hybrid animation is all about leveraging both React and D3 for what they do best: React manages rendering and events, D3 calculates the tricky stuff for smooth animations.`}</p>
    <p>{`Here`}{`'`}{`s the recipe:`}</p>
    <ul>
      <li parentName="ul">{`trigger a React effect`}</li>
      <li parentName="ul">{`start a D3 transition`}</li>
      <li parentName="ul">{`create a custom tween`}</li>
      <li parentName="ul">{`use the tween to drive state`}</li>
    </ul>
    <p><a parentName="p" {...{
        "href": "https://carbon.now.sh/?bg=rgba(255,255,255,1)&t=seti&l=javascript&ds=true&wc=true&wa=true&pv=48px&ph=32px&ln=false&code=%20%20useEffect(()%20%3D%3E%20%7B%0A%20%20%20%20d3.selection()%0A%20%20%20%20%20%20.transition(%22pie-reveal%22)%0A%20%20%20%20%20%20.duration(3000)%0A%20%20%20%20%20%20.ease(d3.easeSinInOut)%0A%20%20%20%20%20%20.tween(%22percentVisible%22%2C%20()%20%3D%3E%20%7B%0A%20%20%20%20%20%20%20%20const%20percentInterpolate%20%3D%20d3.interpolate(0%2C%20100)%3B%0A%20%20%20%20%20%20%20%20return%20t%20%3D%3E%20setPercentVisible(percentInterpolate(t))%3B%0A%20%20%20%20%20%20%7D)%3B%0A%20%20%7D%2C%20%5BrenderData%5D)%3B"
      }}><figure parentName="a" {...{
          "className": "gatsby-resp-image-figure",
          "style": {
            "textAlign": "center",
            "fontStyle": "italic"
          }
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "590px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "59.19282511210761%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'237\\'%20viewBox=\\'0%200%20400%20237\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M23%2034a2781%202781%200%20001%20170h353l1-86V34l-178-1-177%201\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/acfd2f8316c4db333c4b844ada1d4c34/ca0a1/techletter.app-screenshot-1561996963872.webp 223w", "/static/acfd2f8316c4db333c4b844ada1d4c34/75680/techletter.app-screenshot-1561996963872.webp 445w", "/static/acfd2f8316c4db333c4b844ada1d4c34/5ca24/techletter.app-screenshot-1561996963872.webp 590w"],
                "sizes": "(max-width: 590px) 100vw, 590px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/acfd2f8316c4db333c4b844ada1d4c34/e92b6/techletter.app-screenshot-1561996963872.png 223w", "/static/acfd2f8316c4db333c4b844ada1d4c34/e66bf/techletter.app-screenshot-1561996963872.png 445w", "/static/acfd2f8316c4db333c4b844ada1d4c34/fcda8/techletter.app-screenshot-1561996963872.png 590w"],
                "sizes": "(max-width: 590px) 100vw, 590px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/acfd2f8316c4db333c4b844ada1d4c34/fcda8/techletter.app-screenshot-1561996963872.png",
                "alt": "Click through for source",
                "title": "Click through for source",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`Click through for source`}</figcaption>{`
  `}</figure></a></p>
    <p><inlineCode parentName="p">{`useEffect`}</inlineCode>{` runs its method when `}<inlineCode parentName="p">{`renderData`}</inlineCode>{` changes. That`}{`'`}{`s every time you drilldown or drillup.`}</p>
    <p>{`The effect runs a D3 transition on an empty selection. Sets duration to 3 seconds, an easing function to look pretty, and fires off a custom tween.`}</p>
    <p><a parentName="p" {...{
        "href": "https://carbon.now.sh/?bg=rgba(255,255,255,1)&t=seti&l=javascript&ds=true&wc=true&wa=true&pv=48px&ph=32px&ln=false&code=()%20%3D%3E%20%7B%0A%09const%20percentInterpolate%20%3D%20d3.interpolate(0%2C%20100)%3B%0A%09return%20t%20%3D%3E%20setPercentVisible(percentInterpolate(t))%3B%0A%7D"
      }}><figure parentName="a" {...{
          "className": "gatsby-resp-image-figure",
          "style": {
            "textAlign": "center",
            "fontStyle": "italic"
          }
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "558px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "43.04932735426009%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'173\\'%20viewBox=\\'0%200%20400%20173\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M23%2036v52l1%2051h10c22%202%20341%201%20342-1l1-52V36l-177-1-177%201\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/c87c03b4e186c71ea7ee8f86de80bc05/ca0a1/techletter.app-screenshot-1561996963200.webp 223w", "/static/c87c03b4e186c71ea7ee8f86de80bc05/75680/techletter.app-screenshot-1561996963200.webp 445w", "/static/c87c03b4e186c71ea7ee8f86de80bc05/1c397/techletter.app-screenshot-1561996963200.webp 558w"],
                "sizes": "(max-width: 558px) 100vw, 558px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/c87c03b4e186c71ea7ee8f86de80bc05/e92b6/techletter.app-screenshot-1561996963200.png 223w", "/static/c87c03b4e186c71ea7ee8f86de80bc05/e66bf/techletter.app-screenshot-1561996963200.png 445w", "/static/c87c03b4e186c71ea7ee8f86de80bc05/42a8d/techletter.app-screenshot-1561996963200.png 558w"],
                "sizes": "(max-width: 558px) 100vw, 558px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/c87c03b4e186c71ea7ee8f86de80bc05/42a8d/techletter.app-screenshot-1561996963200.png",
                "alt": "Click through for source",
                "title": "Click through for source",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`Click through for source`}</figcaption>{`
  `}</figure></a></p>
    <p>{`Tweens are meant to manipulate DOM attributes, but when you think about it, they`}{`'`}{`re just functions that run on every step of the transition. They can do whatever you want to do on every keyframe. 🤔`}</p>
    <p>{`We start with an interpolator from `}<inlineCode parentName="p">{`0`}</inlineCode>{` to `}<inlineCode parentName="p">{`100`}</inlineCode>{`. Interpolators translate the time parameter, `}<inlineCode parentName="p">{`t`}</inlineCode>{`, into a smooth transition from start to end. Easing functions manipulate that `}<inlineCode parentName="p">{`t`}</inlineCode>{` parameter to create fun effects.`}</p>
    <p>{`Interpolator in hand, we return a parametrized function that updates state with `}<inlineCode parentName="p">{`setPercentVisible`}</inlineCode>{` using the interpolator.`}</p>
    <h3 {...{
      "id": "percentvisible-state"
    }}>{`percentVisible state`}</h3>
    <p>{`Using that state change to animate our piechart looks like this:`}</p>
    <p><a parentName="p" {...{
        "href": "https://carbon.now.sh/?bg=rgba(255,255,255,1)&t=seti&l=javascript&ds=true&wc=true&wa=true&pv=48px&ph=32px&ln=false&code=%20%20const%20%5BpercentVisible%2C%20setPercentVisible%5D%20%3D%20useState(0)%3B%0A%0A%20%20const%20pie%20%3D%20d3%0A%20%20%20%20.pie()%0A%20%20%20%20.startAngle(startAngle)%0A%20%20%20%20.endAngle(startAngle%20%2B%20percentVisible%20*%20Math.PI%20*%202)%0A%20%20%20%20.value(d%20%3D%3E%20d.value)%3B"
      }}><figure parentName="a" {...{
          "className": "gatsby-resp-image-figure",
          "style": {
            "textAlign": "center",
            "fontStyle": "italic"
          }
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "566px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "52.017937219730946%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'208\\'%20viewBox=\\'0%200%20400%20208\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M23%2036c-1%203-1%20137%201%20138%202%202%20350%202%20352%200%202-1%202-135%201-138S24%2033%2023%2036\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/934def84611278c5ad7eeab5b82ffc0b/ca0a1/techletter.app-screenshot-1561996980269.webp 223w", "/static/934def84611278c5ad7eeab5b82ffc0b/75680/techletter.app-screenshot-1561996980269.webp 445w", "/static/934def84611278c5ad7eeab5b82ffc0b/3161c/techletter.app-screenshot-1561996980269.webp 566w"],
                "sizes": "(max-width: 566px) 100vw, 566px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/934def84611278c5ad7eeab5b82ffc0b/e92b6/techletter.app-screenshot-1561996980269.png 223w", "/static/934def84611278c5ad7eeab5b82ffc0b/e66bf/techletter.app-screenshot-1561996980269.png 445w", "/static/934def84611278c5ad7eeab5b82ffc0b/6fe44/techletter.app-screenshot-1561996980269.png 566w"],
                "sizes": "(max-width: 566px) 100vw, 566px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/934def84611278c5ad7eeab5b82ffc0b/6fe44/techletter.app-screenshot-1561996980269.png",
                "alt": "Click through for source",
                "title": "Click through for source",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`Click through for source`}</figcaption>{`
  `}</figure></a></p>
    <p><inlineCode parentName="p">{`percentVisible`}</inlineCode>{` and `}<inlineCode parentName="p">{`setPercentVisible`}</inlineCode>{` come from a `}<inlineCode parentName="p">{`useState`}</inlineCode>{` hook. Getter and setter :)`}</p>
    <p>{`D3 pie generators take a `}<inlineCode parentName="p">{`startAngle`}</inlineCode>{` and `}<inlineCode parentName="p">{`endAngle`}</inlineCode>{` config and fit the entire piechart between those angles. If we keep changing the `}<inlineCode parentName="p">{`endAngle`}</inlineCode>{` to be closer and closer to a full circle based on `}<inlineCode parentName="p">{`percentVisible`}</inlineCode>{`, we get an animation that looks like a piechart revealing itself.`}</p>
    <p><img parentName="p" {...{
        "src": "/add688a4b40298522cbb93b9c8d5ffa1/ygw2Esu.gif",
        "alt": null
      }}></img></p>
    <p>{`Neat 🤘`}</p>
    <h2 {...{
      "id": "wat-gives"
    }}>{`Wat gives`}</h2>
    <p><img parentName="p" {...{
        "src": "/21ab5c09f8bf0500529d9af1519a2be5/media-3WmWdBzqveXaE-giphy.gif",
        "alt": "wat giphy"
      }}></img></p>
    <p>{`The trick here is that React hooks are magic and the tree diffing algorithm is fast as hell.`}</p>
    <p>{`On each step of the transition, our tween changes state and triggers a re-render. When the piechart re-renders, it uses the updated angle.`}</p>
    <p>{`The updated angle creates a bigger piechart. The arc components adjust and make themselves fit inside.`}</p>
    <p>{`Crucially, React doesn`}{`'`}{`t re-run the effect. Nothing that our effect relies on changed, so it keeps running uninterrupted.`}</p>
    <p>{`The result is a powerful new animation technique ✌️`}</p>
    <p>{`Cheers,`}</p>
    <p>{`~Swizec`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      